import React, { useEffect, useState } from 'react'
import { Header, Footer, Infobox } from "../Components"
import { useParams, useNavigate } from 'react-router-dom'
import { MdDelete } from "react-icons/md"
import { toast } from 'react-toastify'
import { Api, Images } from '../Constants'
import { useForm } from "react-hook-form";
import moment from 'moment';
import Select from 'react-select'
import { FaEdit } from 'react-icons/fa'

const Bookupdate = () => {
    const [isPageLoading, setIsPageLoading] = React.useState(true);
    const [showModal, setShowModal] = useState(false)
    const [removeModal, setRemoveModal] = useState(false)
    const [isAdd, setisAdd] = useState(false)
    const [qtyError, setqtyError] = useState(false)
    const [tickets, setTickets] = useState([])
    const [selectedTicket, setselectedTicket] = useState(null)
    const [selectedTicketID, setselectedTicketID] = useState()
    const [tableData, settableData] = useState([])
    const [paymentMethod, setpaymentMethod] = useState([])
    const [quantity, setQuantity] = useState(1)
    const [orderID, setOrderID] = useState(null)
    const [clickedID, setclickedID] = useState(null)
    const [customerID, setcustomerID] = useState(null)
    const [footData, setfootData] = useState({ subTotal: null, discount: null, taxable: null, vat: null, total: null })
    const {
        register,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm()
    const [isLoading, setIsLoading] = React.useState(false);
    const [isUpdateLoading, setIsUpdateLoading] = React.useState(false);
    let navigate = useNavigate();
    const params = useParams();
    const [choseTicket, setChoseTicket] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
        setOrderID(params.id)
        const getData = async () => {
            const res = await Api.getTickets()
            const payres = await Api.getPaymentMethod()
            if (res.status) {
                const Tickets = res.data.customer_type.map((elem) => {
                    let dplabel = parseFloat(elem.rate) - parseFloat(elem.discount_amount)
                    dplabel = dplabel.toFixed(2)
                    dplabel = elem.name + ' ( Rs.' +dplabel+ ' )'
                return {
                    ...elem,
                    value: elem.id,
                    label: dplabel,
                }
                })
                setTickets(Tickets)
                let defaultTicket = Tickets[0]
                setselectedTicket(defaultTicket)
                setselectedTicketID(defaultTicket.value)
                updatePriceData(quantity, defaultTicket.rate, defaultTicket.discount_amount)
            }
            
            const orderres = await Api.getOrders(params.id)
            if (orderres.status) {
                let customer = orderres.data.Customer
                setValue('name', customer.customer_name);
                setValue('email', customer.email);
                setValue('phone', customer.phone);
                setValue('address', customer.address);
                setValue('customer_pan', customer.customer_pan);

                // setcustomerID(orderres.data.Customer.id)
                let customer_ticket = orderres.data.tickets[0].customer_ticket
                updateTableData(customer_ticket, orderres.data.tickets[0])
                setIsPageLoading(false)
            }else{
                if (!toast.isActive('1')) {
                    toast.error('Failed to load data.');
                    setIsPageLoading(true)
                }
                return navigate("/");
            }

            if(payres.status){
                let resdata = payres.data.data
                let newdata = resdata.map(elem => {
                    let imgUrl
                    switch(elem.name.toLowerCase()){
                        case 'fonepay':
                            imgUrl = Images.fonepay
                            break;
                        case 'esewa':
                            imgUrl = Images.esewa
                            break;
                        case 'khalti':
                            imgUrl = Images.khalti
                            break;
                        default:
                            imgUrl = Images.rupee
                    }
                    return {
                        ...elem,
                        imgUrl: imgUrl,
                    }
                })
                setpaymentMethod(newdata)
            }else {
                if (!toast.isActive('1')) {
                    toast.error('Failed to load payment option data.');
                }
            }
        }
        getData()

        return () => {};
    }, []);

    const updateTableData = (customer_ticket, tickets) => {
        settableData(customer_ticket)
        // setfootData({
        //     subTotal: tickets.totalamounts[0].total,
        //     discount: tickets.discount_customer_ticket[0].discount, 
        //     taxable: tickets.[0]. , 
        //     vat: tickets.[0]. , 
        //     total: tickets.[0]. 
        // })
        let subTotal = 0
        let discount = 0
        let taxable = 0
        let vat = 0
        let total_amount = 0

        customer_ticket.forEach(element => {
            subTotal = parseFloat(subTotal) + parseFloat(element.amount)
            discount = parseFloat(discount) + parseFloat(element.discount)
            taxable = parseFloat(taxable) + parseFloat(element.taxable_amount)
            vat = parseFloat(vat) + parseFloat(element.vat_amount)
            total_amount = parseFloat(total_amount) + parseFloat(element.total_amount)
            setfootData({
                subTotal: subTotal.toFixed(2),
                discount: discount.toFixed(2), 
                taxable: taxable.toFixed(2), 
                vat: vat.toFixed(2), 
                total: total_amount.toFixed(2)
            })
        });
    }

    const onChangeTicket = ticket_id => {
        let selectedTicket = tickets.filter(elem => elem.id === ticket_id)
        selectedTicket = selectedTicket[0]
        setselectedTicket(selectedTicket)
        updatePriceData(quantity, selectedTicket.rate, selectedTicket.discount_amount)
    }

    const onChangeQantity = qty => {
        if(qty < 1) {
            setqtyError(true)
        }else {
            setqtyError(false)
            setQuantity(qty)
            updatePriceData(qty, selectedTicket.rate, selectedTicket.discount_amount)
        }
    }

    const updatePriceData = (qty, rate, discount_amount) => {
        let discountedPrice = qty * (rate - discount_amount)
        discountedPrice = discountedPrice.toFixed(2)
        let price = qty * rate
        price = price.toFixed(2)
        let discount_amt = qty * discount_amount
        discount_amt = discount_amt.toFixed(2)
        updateSelectedTicket({ discountedPrice: discountedPrice });
        updateSelectedTicket({ quantity: qty });
        updateSelectedTicket({ price: price });
        updateSelectedTicket({ calculated_discount: discount_amt });
    }

    const updateSelectedTicket = (newKeyValue) => {
        setselectedTicket(prevState => ({
          ...prevState,  
          ...newKeyValue 
        }));
    };

    const saveTicket = async () => {
        setIsUpdateLoading(true)
        let postData = {}
        if(isAdd) {
            postData = {
                ticketpriceid:  selectedTicketID,
                customer_id: orderID,
                quantity: quantity,
                is_add: 'is_add',
            }
        }else {
            postData = {
                ticketpriceid:  selectedTicketID,
                customer_id: orderID,
                quantity: quantity,
                customer_ticket_id: clickedID,
            }
        }
        const res = await Api.updateTicket(postData)
        if(res.status) {
            console.log('form success',res.data.customer_id)
            const orderres = await Api.getOrders(params.id)
            if (orderres.status) {
                let customer_ticket = orderres.data.tickets[0].customer_ticket
                updateTableData(customer_ticket, orderres.data.tickets[0])
                setQuantity(1)
                setShowModal(false)
            }else{
                if (!toast.isActive('1')) {
                    toast.error('Failed to load data.');
                }
            }
            setisAdd(false)
            setIsUpdateLoading(false)
            // return navigate("/confirm/"+res.data.customer_id);
        }else {
            setIsUpdateLoading(false)
            console.log('form error',res.data)
            toast.error('Failed to save ticket.');
            // setisAdd(false)
        }
    }

    const showEditModal = (customer_ticket_price_id) => {
        let defaultTicket = tickets.filter(elem => elem.id === customer_ticket_price_id)
        defaultTicket = defaultTicket[0]
        setselectedTicket(defaultTicket)
        setselectedTicketID(defaultTicket.value)
        updatePriceData(quantity, defaultTicket.rate, defaultTicket.discount_amount)
        setShowModal(true)
    }

    const removeTicket = async () => {
        const res = await Api.removeTicket(clickedID)
        if(res.status) {
            const orderres = await Api.getOrders(params.id)
            if (orderres.status) {
                let customer_ticket = orderres.data.tickets[0].customer_ticket
                updateTableData(customer_ticket, orderres.data.tickets[0])
                setRemoveModal(false)
                setclickedID(null)
                toast.success('Ticket removed successfully')
            }else{
                if (!toast.isActive('1')) {
                    toast.error('Failed to load data.');
                }
            }
        }else {
            setIsLoading(false)
            console.log('form error',res.data)
            toast.error('Failed to add ticket.');
        }
    }

    const onSubmit = async (data) => {
        setIsLoading(true)
        
        const postData = {
            ...data,
            date: moment(new Date()).format('YYYY/MM/DD'),
            // customer_ticket_price_id: ticketIDQTy
        }
        const res = await Api.updateCustomer(postData,orderID)
        if(res.status) {
            console.log('form success',res.data.customer_id)
            return navigate("/confirm/"+res.data.customer_id);
        }else {
            setIsLoading(false)
            console.log('form error',res.data)
            toast.error('Failed to add ticket.');
        }
    }
return(
<div>
    {isPageLoading ? (
    <div className='my-spinner-w'>
        <div className="spinner-grow" style={{'width': '3rem','height': '3rem'}}>
        </div>
        <div className='ms-3 mt-3'>Loading...</div>
    </div>) 
    : (<>
    <Header breadcrumb={choseTicket ? "Edit Customer Details" : "Edit Ticket"} />
    <div className="sm-section-container">
        <div className="container">
            <h1 className='pt-2'>Book a Ticket</h1>
            {isPageLoading ? 
            <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
            :
            <div className="row desktop-book pb-5">
                <div className="col-md-9">
                    <div className="border-rt-dot pe-3">
                    <form onSubmit={handleSubmit(onSubmit)}>
                    {!choseTicket &&
                        <>
                        <div id="formindicator">
                        <div class="form-header d-flex mb-4 mt-2">
                            <span class="stepIndicator active">Choose Ticket</span>
                            <span class="stepIndicator">Customer Details</span>
                            <span class="stepIndicator">Confirmation</span>
                        </div>
                        </div>
                        <h4 className="pb-3">Edit Ticket</h4>
                        <div className="row">
                            <div className="col-lg-9">
                                {tableData.length == 0 &&
                                    <small>You have not added any ticket. Please click on 'Add Ticket' button to book a ticket</small>
                                }
                            </div>
                            <div className="col-lg-3 text-end">
                                <button type="button" 
                                    title="Add Ticket"
                                    className="btn btn-success my-btn-success btn-sm_" 
                                    onClick={()=> {
                                        setShowModal(true)
                                        setisAdd(true)
                                    }}>
                                    + Add Ticket
                                </button>
                            </div>  
                        </div>
                        {tableData.length > 0 && 
                        <>
                        <div className='table-responsive'>
                        <table className="table table-striped mt-2 table-transp">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Particulars</th>
                                    <th scope="col">Rate (Rs.)</th>
                                    <th scope="col-span-1">Quantity</th>
                                    <th scope="col">Price (Rs.)</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody id="table-value">
                                {tableData.map((elem,index) => (
                                <tr key={index}>
                                    <td>{index +1}</td>
                                    <td>{elem.customer_ticket_type.name}</td>
                                    <td>{elem.customer_ticket_type.rate}</td>
                                    <td>1</td>
                                    <td>{elem.customer_ticket_type.rate}</td>
                                    <td>
                                        <button className='btn btn-sm btn-primary d-inline-flex' title="Edit"
                                            type='button'
                                            onClick={()=>{
                                                setclickedID(elem.id)
                                                showEditModal(elem.customer_ticket_price_id)
                                            }}
                                            >
                                            <FaEdit />
                                        </button>
                                        <button className='btn btn-sm btn-danger ms-lg-2 d-inline-flex' title="Remove"
                                            type='button'
                                            onClick={()=>{
                                                setclickedID(elem.id)
                                                setRemoveModal(true)
                                            }}>
                                            <MdDelete />
                                        </button>
                                    </td>
                                </tr>
                                ))}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan={3}></td>
                                    <td><b id="total-rate">Sub Total: </b></td>
                                    <td><b id="total-price">{footData.subTotal}</b></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td colSpan={3}></td>
                                    <td><b id="total-rate">Discount: </b></td>
                                    <td><b id="total-price">{footData.discount}</b></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td colSpan={3}></td>
                                    <td><b id="total-rate">Taxable Amount: </b></td>
                                    <td><b id="total-price">{footData.taxable}</b></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td colSpan={3}></td>
                                    <td><b id="total-rate">VAT 13%: </b></td>
                                    <td><b id="total-price">{footData.vat}</b></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td colSpan={3}></td>
                                    <td><b id="total-rate">Total: </b></td>
                                    <td><b id="total-price">{footData.total}</b></td>
                                    <td></td>
                                </tr>
                            </tfoot>
                        </table>
                        </div>
                        {tableData.length > 0 && 
                            <div className="form-group mt-2 text-end ">
                                <button 
                                    type="button" 
                                    className="btn btn-success my-btn-success"
                                    onClick={()=> {
                                        setChoseTicket(true)
                                        window.scrollTo(0, 0)
                                    }}
                                    title="Edit">
                                    Edit
                                </button>
                            </div>
                        }
                        </>
                        }
                        </>
                        }
                        {choseTicket &&
                        <>
                        <div id="formindicator">
                        <div class="form-header d-flex mb-4 mt-2">
                            <span class="stepIndicator finish">Choose Ticket</span>
                            <span class="stepIndicator active">Customer Details</span>
                            <span class="stepIndicator">Confirmation</span>
                        </div>
                        </div>
                        <h4 className="pb-3">Edit Customer Details</h4>
                        <div className="row mb-xl-3">
                            <label className="col-xl-1 col-form-label">Name*</label>
                            <div className="col-xl-5">
                                <input 
                                    className='form-control'
                                    {...register("name", { required: true })} 
                                />
                                {errors.name && <span className='text-danger'>This field is required</span>}
                            </div>
                            <label className="col-xl-1 col-form-label">Email*</label>
                            <div className="col-xl-5">
                                <input 
                                    className='form-control'
                                    {...register('email', { required: true, pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/ })}
                                />
                                {errors.email && errors.email.type === 'required' && <span className="text-danger">This field is required</span>}
                                {errors.email && errors.email.type === 'pattern' && <span className="text-danger">Email is invalid</span>}
                            </div>
                        </div>
                        <div className="row mb-xl-3">
                            <label className="col-xl-1 col-form-label">Phone*</label>
                            <div className="col-xl-5">
                                <input 
                                    placeholder="e.g 9876543210"
                                    className='form-control'
                                    {...register('phone', { required: true, pattern: /^\+?(?:[0-9] ?){6,14}[0-9]$/ })}
                                />
                                {errors.phone && errors.phone.type === 'required' && <span className="text-danger">This field is required</span>}
                                {errors.phone && errors.phone.type === 'pattern' && <span className="text-danger">Phone is invalid</span>}
                            </div>

                            <label className="col-xl-1 col-form-label">Address</label>
                            <div className="col-xl-5">
                                <input 
                                    className='form-control'
                                    {...register("address", { required: false })} 
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label className="col-xl-1 col-form-label">Customer Pan</label>
                            <div className="col-xl-5">
                                <input 
                                    className='form-control'
                                    placeholder="e.g 123456789"
                                    {...register("customer_pan", { required: false, pattern: /^[0-9]{9}$/ })} 
                                />
                                {errors.customer_pan && errors.customer_pan.type === 'pattern' && <span className="text-danger">Customer Pan is invalid</span>}
                            </div>
                        </div>
                        <div className="form-group my-4 text-end ">

                            {isLoading ? (
                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            ) : (
                                <button 
                                    type="submit" 
                                    className="btn btn-success my-btn-success"
                                    title="Edit">
                                    Edit
                                </button>
                            )}
                        </div>
                        </>
                        }
                        </form>
                    </div>
                </div>
                <div className="col-md-3">
                    <Infobox paymentMethod={paymentMethod} />
                </div>
            </div>
            }
        </div>
    </div>

    <div className={showModal ? 'modal my-modal fade show' : 'modal my-modal fade'} tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Ticket Information</h5>
                    <button type="button" className="btn-close" 
                        onClick={()=>setShowModal(false)}
                        aria-label="Close"></button>
                </div>
                
                <div className="modal-body">
                    <h6>Please provide the necessary information to buy your tickets.
                    </h6>
                    <div className="row my-3">
                        <label className="col-md-3 col-form-label">Ticket Type</label>
                        <div className="col-md-9">
                            <Select
                                options={tickets}
                                value={tickets.filter((option) => option.id === selectedTicketID)}
                                onChange={(e) => {
                                    setselectedTicketID(e.value)
                                    onChangeTicket(e.value)
                                }}
                            />
                        </div>
                        <span className="error-text name_error"></span>
                    </div>
                    <div className="row mb-3">
                        <label
                            className="col-md-3 col-form-label">Quantity</label>
                        <div className="col-md-3">
                            <input type="number" min="1" className="form-control inputs"
                                value={quantity}
                                disabled={selectedTicket === null}
                                onChange={(e)=> onChangeQantity(e.target.value)}
                                />
                            {qtyError && <span className="text-danger">Minimum quantity should be 1</span>}
                        </div>
                    </div>
                    {selectedTicket !== null && 
                        <>
                        <div className="row mb-3">
                            <label
                                className="col-md-3 col-form-label">Rate (Rs.)</label>
                            <div className="col-md-9">
                                <label className='col-form-label'> {selectedTicket.rate}</label>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label
                                className="col-md-3 col-form-label">Discount (Rs.)</label>
                            <div className="col-md-9">
                                <label className='col-form-label'> {selectedTicket.calculated_discount}</label>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label
                                className="col-md-3 col-form-label">Price (Rs.)</label>
                            <div className="col-md-9">
                                <h4 className='fw-bold'>{selectedTicket.discountedPrice}</h4>
                            </div>
                        </div>
                        </>
                    }
                </div>
                <div className="modal-footer">
                    {isUpdateLoading ? (
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    ) : (
                        <button type="button" className="btn btn-primary"
                            onClick={() => saveTicket()}
                            title='Save Ticket'
                        >Save
                        </button>
                    )}
                </div>
            </div>
        </div>
    </div>

    <div className={removeModal ? 'modal my-modal fade show' : 'modal my-modal fade'} tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Delete Confirmation</h5>
                    <button type="button" className="btn-close" 
                        onClick={()=>setRemoveModal(false)}
                        aria-label="Close"></button>
                </div>
                
                <div className="modal-body">
                    <h6>Are you sure you want to remove the ticket?
                    </h6>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-primary"
                        onClick={() => setRemoveModal(false)}
                        title='Yes'
                    >No
                    </button>
                    <button type="button" className="btn btn-danger"
                        onClick={() => removeTicket()}
                        title='Yes'
                    >Yes
                    </button>
                </div>
            </div>
        </div>
    </div>
    <Footer />
    </>)}
</div>
)
}

export default Bookupdate
