import { HelperFunction, Images } from '../Constants'
import { Link } from 'react-router-dom'
import { FaAngleRight } from "react-icons/fa";

const Header = (props) => {
return (
<header>
    <nav className="navbar navbar-expand navbar-light bg-white_ w-100 p-0">
        <div className="container header-container">
            <Link className="navbar-brand" to={HelperFunction.WEBSITE_URL}>
                <img src={Images.logo} alt="siddhartha" className="h-logo" />
            </Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse mb-4" id="navbarSupportedContent">
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                        <Link className="nav-link active pe-0" aria-current="page" to={HelperFunction.WEBSITE_URL}>Home</Link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
    <div className="h-btm">
        <div className="container bread-content">
            <Link to={HelperFunction.WEBSITE_URL}>Home</Link>
            <FaAngleRight />
            <Link to={HelperFunction.BASE_URL}>Book a Ticket</Link>
            <FaAngleRight />
            <span style={{'color':'#EB800E', 'fontSize':'0.8rem'}}>{props.breadcrumb}</span>
        </div>
    </div>
</header>
)
}

export default Header
