import axios from 'axios'
import { toast } from 'react-toastify'
// import { store } from '../index'
// import { performLogout } from '../Pages/Welcome/actions'
import HelperFunction from './HelperFunction'

axios.defaults.baseURL = HelperFunction.BASE_URL

const regex = /(<([^>]+)>)/gi

const getHeaders = async () => {
  // const token = localStorage.getItem('token')

  const headers = {
    'Content-Type': 'application/json',
    // Authorization: `Bearer ${token}`,
  }
  // new Api().tokenVerification(headers)
  return headers
}

class Api {
  getTickets = async () => {
    try {
      const data = await axios.get('/gettickets', {
        headers: await getHeaders(),
      })
      const res = {
        data: data.data,
        status: true
      }
      console.log('gettickets Res:', res)
      return res
    } catch (e) {
      const res = {
        data: e.response.data,
        status: false
      }
      console.log('gettickets Error', e.response.data)
      return res
    }
  }
  postTicketBook = async (postData) => {
    try {
      const data = await axios.post('/store', postData, {
        headers: await getHeaders(),
      })
      const res = {
        data: data.data,
        status: true
      }
      console.log('postTicketBook Res:', res)
      return res
    } catch (e) {
      const res = {
        data: e.response.data,
        status: false
      }
      console.log('postTicketBook Error', e.response.data)
      return res
    }
  }
  getOrders = async (orderID) => {
    try {
      const data = await axios.get('/show/'+orderID, {
        headers: await getHeaders(),
      })
      const res = {
        data: data.data,
        status: true
      }
      console.log('getOrders Res:', res)
      return res
    } catch (e) {
      const res = {
        data: e.response.data,
        status: false
      }
      console.log('getOrders Error', e.response.data)
      return res
    }
  }
  getPaymentMethod = async () => {
    try {
      const data = await axios.get('/getpaymentmethod', {
        headers: await getHeaders(),
      })
      const res = {
        data: data.data,
        status: true
      }
      console.log('getPaymentMethod Res:', res)
      return res
    } catch (e) {
      const res = {
        data: e.response.data,
        status: false
      }
      console.log('getPaymentMethod Error', e.response.data)
      return res
    }
  }
  postPaymentMethod = async (postData,orderID) => {
    try {
      const data = await axios.put('/updatepaymentmethod/'+orderID, postData, {
        headers: await getHeaders(),
      })
      const res = {
        data: data.data,
        status: true
      }
      console.log('postPaymentMethod Res:', res)
      return res
    } catch (e) {
      const res = {
        data: e.response.data,
        status: false
      }
      console.log('postPaymentMethod Error', e.response.data)
      return res
    }
  }
  updateCustomer = async (postData,orderID) => {
    try {
      const data = await axios.put('/updatecustomer/'+orderID, postData, {
        headers: await getHeaders(),
      })
      const res = {
        data: data.data,
        status: true
      }
      console.log('updateCustomer Res:', res)
      return res
    } catch (e) {
      const res = {
        data: e.response.data,
        status: false
      }
      console.log('updateCustomer Error', e.response.data)
      return res
    }
  }
  updateTicket = async (postData) => {
    try {
      const data = await axios.post('/update', postData, {
        headers: await getHeaders(),
      })
      const res = {
        data: data.data,
        status: true
      }
      console.log('updateTicket Res:', res)
      return res
    } catch (e) {
      const res = {
        data: e.response.data,
        status: false
      }
      console.log('updateTicket Error', e.response.data)
      return res
    }
  }
  removeTicket = async (ticketID) => {
    try {
      const data = await axios.delete('/removeTicket/'+ticketID, {
        headers: await getHeaders(),
      })
      const res = {
        data: data.data,
        status: true
      }
      console.log('removeTicket Res:', res)
      return res
    } catch (e) {
      const res = {
        data: e.response.data,
        status: false
      }
      console.log('removeTicket Error', e.response.data)
      return res
    }
  }
  bookingconfirm = async (orderID) => {
    try {
      const data = await axios.get('/bookingconfirm/'+orderID, {
        headers: await getHeaders(),
      })
      const res = {
        data: data.data,
        status: true
      }
      console.log('bookingconfirm Res:', res)
      return res
    } catch (e) {
      const res = {
        data: e.response.data,
        status: false
      }
      console.log('bookingconfirm Error', e.response.data)
      return res
    }
  }
  

  performLogin = async (postData) => {
    // console.log('performLogin postData', postData)

    try {
      const data = await axios.post('/auth/login', postData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      const res = data.data

      console.log('performLogin Res:', res)
      return res
    } catch (e) {
      console.log('performLogin Error', e.response.status)
      return e.response.status
    }
  }

  performLogout = async (postData) => {
    try {
      const data = await axios.post('/auth/logout', postData, {
        headers: await getHeaders(),
      })
      const res = data.data

      console.log('performLogout Res:', res)
      return res
    } catch (e) {
      console.log('performLogout Error', e.response.data)
      return false
    }
  }

  changePassword = async (postData) => {
    console.log('changePassword postData', postData)
    try {
      const data = await axios.post('/auth/change_password', postData, {
        headers: await getHeaders(),
      })
      const res = data.data

      console.log('changePassword Res:', res)
      return res
    } catch (e) {
      console.log('changePassword Error', e.response.data)
      return false
    }
  }

  forgotPassword = async (postData) => {
    console.log('forgotPassword postData', postData)
    try {
      const data = await axios.post('/auth/forgot_password', postData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      const res = data.data

      console.log('forgotPassword Res:', res)
      return res
    } catch (e) {
      console.log('forgotPassword Error', e.response.data)
      return e.response.data
    }
  }

  tokenVerification = async (headers) => {
    try {
      const data = await axios.get('/auth/app_load_token_verification', {
        headers: headers,
      })
      const res = data.data

      // console.log('tokenVerification Res:', res)
      return res
    } catch (e) {
      // console.log('tokenVerification Error', e)

      const token = localStorage.getItem('token')
      if (token) {
        toast.error('You are being logged out from this browser because you have logged in from another device.')
      }

      localStorage.removeItem('token')
      // store.dispatch(performLogout())
      // toast.error('You have active session in another browser')
      return false
    }
  }


  deleteInvoice = async (postData) => {
    console.log('deleteInvoice postData', postData)
    try {
      const data = await axios.post(
        '/property/remove_invoice_document',
        postData,
        {
          headers: await getHeaders(),
        }
      )
      const res = data.data

      console.log('deleteInvoice Res:', res)
      return res
    } catch (e) {
      console.log('deleteInvoice Error', e.response.data)
      return false
    }
  }

  postUser = async (postData) => {
    try {
      const data = await axios.post('/users', postData, {
        headers: await getHeaders(),
      })
      const res = {
        data: data.data.data,
        status: true
      }
      console.log('postUser Res:', res)
      return res
    } catch (e) {
      const res = {
        data: e.message,
        status: false
      }
      console.log('postUser Error', e.message)
      return res
    }
  }

}



export default new Api()
