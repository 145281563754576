import React, { useEffect, useState } from 'react'
import { Header, Footer, Customerinfo, Tickettable } from "../Components"
import { Link, useParams, useNavigate } from "react-router-dom"
import { toast } from 'react-toastify'
import { Api, Images } from '../Constants'
import { useForm } from "react-hook-form";

const Confirm = () => {
    const [isPageLoading, setIsPageLoading] = React.useState(true);
    const [customer, setCustomer] = useState({})
    const [tableData, settableData] = useState([])
    const [orderID, setOrderID] = useState(null)
    const [footData, setfootData] = useState({ subTotal: null, discount: null, taxable: null, vat: null, total: null })
    const [paymentMethod, setpaymentMethod] = useState([])
    const params = useParams();
    let navigate = useNavigate();
    const [isLoadingButton, setIsLoadingButton] = React.useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm()

    useEffect(() => {
        window.scrollTo(0, 0)
        setOrderID(params.id)
        const getData = async () => {
            const res = await Api.getOrders(params.id)
            const payres = await Api.getPaymentMethod()
            if (res.status) {
                setCustomer(res.data.Customer)
                let customer_ticket = res.data.tickets[0].customer_ticket
                settableData(customer_ticket)
                let subTotal = 0
                let discount = 0
                let taxable = 0
                let vat = 0
                let total_amount = 0

                customer_ticket.forEach(element => {
                    subTotal = parseFloat(subTotal) + parseFloat(element.amount)
                    discount = parseFloat(discount) + parseFloat(element.discount)
                    taxable = parseFloat(taxable) + parseFloat(element.taxable_amount)
                    vat = parseFloat(vat) + parseFloat(element.vat_amount)
                    total_amount = parseFloat(total_amount) + parseFloat(element.total_amount)
                    setfootData({
                        subTotal: subTotal.toFixed(2),
                        discount: discount.toFixed(2), 
                        taxable: taxable.toFixed(2), 
                        vat: vat.toFixed(2), 
                        total: total_amount.toFixed(2)
                    })
                });
            }else{
                if (!toast.isActive('1')) {
                    toast.error('Failed to load data.');
                }
                return navigate("/");
            }
            if(payres.status){
                let resdata = payres.data.data
                let newdata = resdata.map(elem => {
                    let imgUrl
                    switch(elem.name.toLowerCase()){
                        case 'fonepay':
                            imgUrl = Images.fonepay
                            break;
                        case 'esewa':
                            imgUrl = Images.esewa
                            break;
                        case 'khalti':
                            imgUrl = Images.khalti
                            break;
                        default:
                            imgUrl = Images.rupee
                    }
                    return {
                        ...elem,
                        imgUrl: imgUrl,
                    }
                })
                setpaymentMethod(newdata)
                setIsPageLoading(false)
            }else {
                if (!toast.isActive('1')) {
                    toast.error('Failed to payment data.');
                }
                return navigate("/");
            }
        }
        getData()

        return () => {};
    }, []);
    
    // const confirmBook = async(orderID) => {
    //     setIsLoading(true)
    //     const bookingres = await Api.bookingconfirm(orderID)
    //     if (bookingres.status) {
    //         return navigate("/payment/"+orderID)
    //     }else{
    //         if (!toast.isActive('1')) {
    //             toast.error('Failed to confirm form submit. Please update the ticket data');
    //         }
    //         setIsLoading(false)
    //     }
    // }

    const onSubmit = async (data) => {
        console.log('form data',data)
        setIsLoadingButton(true)
        const res = await Api.postPaymentMethod(data,orderID)
        if(res.status) {
            // console.log('form success',res.data.url)
            // window.location.href = res.data.url
            // return navigate(res.data.url);
            const bookingres = await Api.bookingconfirm(orderID)
            if (bookingres.status) {
                window.location.href = res.data.url
                // return navigate("/payment/"+orderID)
            }else{
                if (!toast.isActive('1')) {
                    toast.error('Failed to confirm form submit. Please update the ticket data');
                }
                setIsLoadingButton(false)
            }
        }else {
            // console.log('form error',res.data)
            toast.error(res.data.message);
            setIsLoadingButton(false)
        }
        
    }
return(

<div>
    {isPageLoading ? (
    <div className='my-spinner-w'>
        <div className="spinner-grow" style={{'width': '3rem','height': '3rem'}}>
        </div>
        <div className='ms-3 mt-3'>Loading...</div>
    </div>) 
    : (<>
    <Header breadcrumb="Confirmation"/>
    <div className="sm-section-container">
        <div className="container">
            <h1 className='pt-2'>Book a Ticket</h1>
            <div className="desktop-book pb-5">
                <div className='row'>
                    <div className="col-md-8">
                        <div id="formindicator">
                        <div class="form-header d-flex mb-4 mt-2">
                            <span class="stepIndicator finish">Choose Ticket</span>
                            <span class="stepIndicator finish">Customer Details</span>
                            <span class="stepIndicator active">Confirmation</span>
                        </div>
                        </div>
                        <h4 className="pb-3">Confirmation</h4>
                        <Tickettable tableData={tableData} footData={footData} />
                    </div>
                    <div className="col-md-4">
                        <Customerinfo customer={customer} />
                    </div>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group mt-2">
                        <label className="col-form-label">Choose Payment Mode :</label>
                        <div className="mt-3">
                            {paymentMethod.map((elem,index) => (
                                <div className="form-check form-check-inline" key={index}>
                                    <input 
                                        className="form-check-input mt-3" 
                                        type="radio" 
                                        name="paymethod"
                                        value={elem.id}
                                        id={"pay"+index}
                                        {...register("payment_id", { required: true })} 
                                        checked
                                    />
                                    <label className="form-check-label cursor-pointer" htmlFor={"pay"+index}>
                                        <img src={elem.imgUrl} alt="siddhartha payment" className="img-fluid pay-img" />
                                    </label>
                                </div>
                            ))}
                            {errors.payment_id && <div className='text-danger mt-2'>Please choose payment mode</div>}
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-md-8">
                            <div className="form-group mt-4 text-end ">
                            {isLoadingButton ? (
                                    <div className="spinner-border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                ) : (
                                    <>
                                <Link 
                                    to={"/update/"+orderID}
                                    type="button" 
                                    className="btn btn-primary me-4" 
                                    title="Edit Information">
                                    Edit
                                </Link>
                                <button
                                    type="submit" 
                                    className="btn btn-success my-btn-success" 
                                    // onClick={()=>confirmBook(orderID)}
                                    title="Pay Now">
                                    Pay Now
                                </button>
                                </>
                            )}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            
        </div>
    </div>
    <Footer />
    </>)}
</div>
)
}

export default Confirm
