
//Local
// const FILE_URL = 'http://127.0.0.1:8000/assets/uploads';
// const BASE_URL = "http://127.0.0.1:8000/api/v1";
// const WEBSITE_URL = "http://localhost:3000";
// const IMAGE_URL = "http://127.0.0.1:8000/";

// Live
const FILE_URL = 'https://demo.siddharthacablecar.com.np/assets/uploads';
const BASE_URL = 'https://demo.siddharthacablecar.com.np/api/v1';
const IMAGE_URL = "https://demo.siddharthacablecar.com.np/";
const WEBSITE_URL = "http://booking.siddharthacablecar.com.np";


export default {
  FILE_URL,
  BASE_URL,
  WEBSITE_URL,
  IMAGE_URL,
};