import React, { useEffect, useState } from 'react'
import { Header, Footer, Customerinfo, Tickettable } from "../Components"
import { Link, useParams, useNavigate, useSearchParams } from "react-router-dom"
import { toast } from 'react-toastify'
import { Api } from '../Constants'

const Success = () => {
    const [customer, setCustomer] = useState({})
    const [tableData, settableData] = useState([])
    const [orderID, setOrderID] = useState(null)
    const [footData, setfootData] = useState({ subTotal: null, discount: null, taxable: null, vat: null, total: null })
    const params = useParams();
    let navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [payStatus, setpayStatus] = useState()

    useEffect(() => {
        setOrderID(params.id)
        let paystatus = searchParams.getAll('payment_status');
        paystatus = paystatus[0]
        // console.log('searchParams',paystatus)
        setpayStatus(paystatus)

        const getData = async () => {
            const res = await Api.getOrders(params.id)
            if (res.status) {
                setCustomer(res.data.Customer)
                let customer_ticket = res.data.tickets[0].customer_ticket
                settableData(customer_ticket)
                let subTotal = 0
                let discount = 0
                let taxable = 0
                let vat = 0
                let total_amount = 0

                customer_ticket.forEach(element => {
                    subTotal = parseFloat(subTotal) + parseFloat(element.amount)
                    discount = parseFloat(discount) + parseFloat(element.discount)
                    taxable = parseFloat(taxable) + parseFloat(element.taxable_amount)
                    vat = parseFloat(vat) + parseFloat(element.vat_amount)
                    total_amount = parseFloat(total_amount) + parseFloat(element.total_amount)
                    setfootData({
                        subTotal: subTotal.toFixed(2),
                        discount: discount.toFixed(2), 
                        taxable: taxable.toFixed(2), 
                        vat: vat.toFixed(2), 
                        total: total_amount.toFixed(2)
                    })
                });
            }else{
                if (!toast.isActive('1')) {
                    toast.error('Failed to load data.');
                }
                return navigate("/");
            }
        }
        getData()

        return () => {};
    }, []);
    
return(

<div>
    <Header breadcrumb="Success"/>
    <div className="sm-section-container">
        <div className="container">
            <h2 className="py-3 text-capitalize"><b>Payment {payStatus}</b></h2>
            
                {payStatus == 'success' 
                ? 
                <h4 className="py-3 text-success "><b>Thank you! Your payment of Rs. {footData.total} is complete.</b></h4>
                :
                <h4 className="py-3 text-danger "><b>Your payment of Rs. {footData.total} has been failed. Please try again.</b></h4>
                }
            
            <div className="row desktop-book pb-5">
                <div className="col-md-8">
                    <Tickettable tableData={tableData} footData={footData} />

                    <div className="form-group mt-4 text-end ">
                        <Link 
                            to="/"
                            type="button" 
                            className="btn btn-success my-btn-success" 
                            onclick=""
                            title="Goto homepage">
                            Homepage
                        </Link>
                    </div>
                </div>
                <div className="col-md-4">
                    <Customerinfo customer={customer} />
                </div>
            </div>
        </div>
    </div>
    <Footer />
</div>
)
}

export default Success
