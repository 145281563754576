import logo from '../assets/images/Logo.png'
import money from '../assets/images/money.png'
import rupee from '../assets/images/rupee.png'
import card from '../assets/images/card1.png'
import fonepay from '../assets/images/phonepay.png'
import khalti from '../assets/images/khalti.png'
import esewa from '../assets/images/esewa.png'

const Images = {
  logo,
  money,
  rupee,
  card,
  fonepay,
  khalti,
  esewa,
}

export default Images
