import React, { Suspense } from 'react'
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { Book, Bookupdate, Confirm, Success, Payment } from './Pages';

const loading = (
  <div className="pt-3 text-center">
    Loading
  </div>
)
function App() {
  return (
    <BrowserRouter basename="/">
      <Suspense fallback={loading}>
        <Routes>
          <Route exact path="/confirm/:id" name="Confirm" element={<Confirm />} />
          <Route exact path="/update/:id" name="Book update" element={<Bookupdate />} />
          <Route exact path="/payment/:id" name="Payment" element={<Payment />} />
          <Route exact path="/success/:id" name="Success" element={<Success />} />
          <Route path="*" name="Home" element={<Book />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
